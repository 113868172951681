<template>
  <div class="download">
    <div class="mainpic">
    </div>
    <div class="info">
      <div class="container">
        <div class="tit">选择下载内容</div>
        <!-- 选择 -->
        <div class="downloadinfo" v-if="isdownloadinfo">
          <div class="cardlist">
            <div class="card">
              <h3>黑洞引擎模型格式导出插件及说明手册</h3>
              <p align="center">开发人员可以根据自身的业务特点调用数据接口、定制功能，满足多种应用场景</p>
              <span><a target="_blank" href="http://bjweb.bjblackhole.cn/developcenter/#/index">前往下载</a></span>
            </div>
            <div class="card">
              <h3>星云协同平台用户手册及API说明手册</h3>
              <p align="center">基础平台已满足大部分企业协同管理基本需求，同时也可以根据您的个性化需求增加或减少应用模块</p>
              <span>前往下载</span>
            </div>
          </div>
          <div class="more">
            <p>想要定制方案或企业级方案？</p>
            <p>如果预制方案无法满足您的需求，我们也很乐意为您量身定制。</p>
            <span @click="callus">联系我们</span>
          </div>
        </div>
        <!-- 表单提交 -->
        <div class="forminfo container" v-else>
          <el-form :model="form" :rules="rules" ref="form" label-width="0px" class="demo-ruleForm">
            <el-form-item>个人</el-form-item>
            <el-form-item label="" prop="name">
              <el-input v-model="form.name" placeholder="称呼"></el-input>
            </el-form-item>
             <el-form-item label="" prop="phone">
              <el-input v-model="form.phone" placeholder="电话"></el-input>
            </el-form-item>
             <el-form-item label="" prop="email">
              <el-input v-model="form.email" placeholder="邮箱"></el-input>
            </el-form-item>
            <el-form-item label="" prop="region">
              <!-- <el-select v-model="form.region" placeholder="国家/地区">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select> -->
                <el-cascader
                  size="large"
                  :popper-append-to-body="false"
                  :options="options"
                  v-model="form.region"
                  @change="handleChange">
                </el-cascader>
            </el-form-item>
             <el-form-item label="" prop="region">
              <el-select v-model="form.industry" placeholder="行业" :popper-append-to-body="false">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>公司</el-form-item>
            <el-form-item label="" prop="desc">
              <el-input v-model="form.desc"  placeholder="公司/团队名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="position">
              <el-input v-model="form.position"  placeholder="职务/职位"></el-input>
            </el-form-item>
            <el-form-item label="" prop="contact">
              <el-input v-model="form.contact"  placeholder="联系方式"></el-input>
            </el-form-item>
            <el-form-item>项目</el-form-item>
             <el-form-item label="" prop="projinfo">
              <el-input type="textarea" v-model="form.projinfo"  placeholder="项目描述"></el-input>
            </el-form-item>
             <el-form-item>
                <el-checkbox v-model="checked">我希望销售成员联系我并洽谈关于购买定制许可事宜。</el-checkbox>
             </el-form-item>
            <el-form-item>
              <div class="mybutton">
                <span>提交</span>
                <span>重置</span>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {regionData,CodeToText} from 'element-china-area-data'
import '../../assets/css/cascader.css'
export default {
  data () {
    return {
      isdownloadinfo: false,
      form:{
        name: '',
        phone: '',
        email: '',
        region: '',
        industry: '',
        company: '',
        position: '',
        contact: '',
        projinfo: ''
      },
      rules: {},
      options: regionData,
      selectedOptions: [],
      addtions:{},
      checked: false
    }
  },
  methods: {
    callus () {
      this.isdownloadinfo = false
    },
    handleChange (value) {
      //我们选择地址后，selectedOptions 会保存对应的区域码，例如北京的区域码为'110000'
      //我们要取出区域码对应的汉字，就需要用CodeToText(区域码)进行输出
      this.addtions.selectedOptions = value
      var name = ''
      this.selectedOptions.map(item => name += CodeToText[item] + '/')
      this.addtions.names = name
      // console.log(this.addtions.names)
      // console.log(this.addtions)
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
a{
  color: #fff;
}
.download{
  color: #fff;
  .mainpic{
    width: 100%;
    height: 50vh;
    min-width: 400px;
    background: url('../../assets/image/download/main.png') center center no-repeat;
    background-size: cover;
  }
  .info{
    width: 100%;
    // height: 70vh;
    background: url('../../assets/image/download/bg.jpg') center center no-repeat;
    background-size: cover;
    padding-bottom: 60px;
    .tit{
      font-size: 30px;
      line-height: 120px;
      text-align: center;
    }
    .downloadinfo{
      .cardlist{
        width: 100%;
        overflow: hidden;
        .card{
          width: calc(50% - 20px);
          height: 250px;
          background-color: #fff;
          float: left;
          margin-right: 40px;
          border-radius: 5px;
          color: #000;
          padding: 20px 40px;
          text-align: center;
          overflow: hidden;
          position: relative;
          @media screen and (max-width:768px) {
            width: 80%;
            float: none;
            margin: 30px auto;
          }
          h3{
            // line-height: 50px;
            margin: 20px 0;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          p{
            margin-top: 30px;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          span{
            width: 120px;
            height: 40px;
            display: block;
            border-radius: 5px;
            color: #fff;
            background-color: #0aaff1;
            line-height: 40px;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
          }
        }
        .card:last-child{
          margin: 0;
          @media screen and (max-width:768px) {
            margin: 30px auto;
          }
        }
      }
      .more{
        text-align: center;
        margin-top: 60px;
        p:first-child{
          font-size: 18px;
          margin-bottom: 5px;
        }
        span{
          display: block;
          width: 120px;
          margin: 0 auto;
          margin-top: 50px;
          padding: 5px 20px;
          color: #0aaff1;
          border: 1px solid #0aaff1;
          cursor: pointer;
        }
      }
    }
    .forminfo{
      /deep/ .el-form{
        padding: 20px 30px;
        width: 40%;
        margin: 0 auto;
        background-color: #232323;
        border-radius: 5px;
        border: 1px solid #666666;
        overflow: hidden;
        color: #fff;
        .el-input__inner,
        .el-textarea__inner,
        .el-select-dropdown,
        .el-select-dropdown__item .hover,
        .el-cascader__dropdown{
          background-color: #232426;
          color: #fff;
        }
        .el-select,
        .el-cascader{
          width: 100%;
        }
        .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
          background-color: #232323;
        }
        .el-select-dropdown__item{
          color: #fff;
        }
        .el-checkbox{
          color: #c0c4cc;
        }
        .el-form-item__content{
          font-size: 16px;
        }
        .mybutton{
          span{
            width: 150px;
            display: inline-block;
            text-align: center;
            height: 40px;
            line-height: 40px;
            border-radius: 5px;
            cursor: pointer;
          }
          span:first-child{
            background-color: #0aaff1;
            margin-right: 30px;
          }
          span:last-child{
            background-color: #000000;
            border: 1px solid #666666;
          }
        }
      }
    }
  }
}
</style>
